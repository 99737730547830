@import url("https://fonts.googleapis.com/css?family=Roboto");

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

html {
  overflow: auto;
  height: 100%;
}
html,
body {
  min-height: 100vh;
  position: relative;
}

body.navbar-open {
  position: fixed;
  width: 100%;
}

.page-wrapper {
  /* max-width: 860px;
  margin: 0 auto; */
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 800px;
  padding: 1rem;
}

.content {
  max-width: 860px;
  margin: 0 auto;
  width: 100%;
}

.title {
  background: url(../images/line.png) left center repeat-x;
  padding: 1rem 0;
}

.page-wrapper ul {
  list-style: none;
}

.page-wrapper ul li {
  padding: 0.8rem 0;
}

.page-wrapper p {
  padding: 0.8rem 0;
  line-height: 1.5rem;
}
/* tratamientos page */
.page-wrapper.tratamientos {
  display: block;
  margin: 0 auto;
  width: 100%;

  height: calc(100vh - 120px);
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.801) 0%,
      rgba(33, 165, 218, 0.521) 100%
    ),
    url(../images/yingpis-kalayom-By1szZOVpHg-unsplash.jpg);
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.tratamientos-text-wrapper li h3 {
  font-size: 1rem;
  color: #454545;
}

.page-wrapper.tratamientos .tratamientos-text-wrapper li {
  font-size: 1rem;
  color: #454545;
  padding: 0.8rem 1rem;
}

li::first-letter {
  text-transform: capitalize;
}

article {
  padding: 0.5rem 1.5rem;
  color: #454545;
}

ul li,
p {
  font-size: 0.8rem;
}

a {
  text-decoration: none;
}
.image-wrapper img {
  width: 100%;
}
.tratamientos ul li img {
  width: 10px;
}

.page-wrapper.urgencias {
  background: url(../images/alex-padurariu-7ByfI6Fpi90-unsplash.jpg);
  background-position: center;
  background-size: cover;
  height: calc(100vh - 120px);
}

.contacto-urgencias a {
  color: white;
  text-decoration: underline;
}

#telefono-urgencias {
  padding: 0.5rem 0;
  font-size: 1.5rem;
}

@media (max-width: 600px) {
  .page-wrapper.urgencias {
    background-position-x: 715px;
  }
}

@media (max-width: 1080px) {
  .page-content {
    flex-direction: column;
  }
}

.financiacion ul li img {
  width: 10px;
}

.financiacion h2 {
  padding: 0.5rem 0;
  line-height: 2rem;
}

#dropdownInfo {
  color: blue;
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}

.arrow-right {
  width: 10px;
}

#dropdownInfo.openDropdown {
  height: auto;
  max-height: 900px;
  transition: max-height 0.25s ease-in;
}

.saberMas {
  color: rgb(39, 109, 167);
  text-decoration: underline;
  cursor: pointer;
}

footer {
  background: #454545;
  display: flex;
  justify-content: center;
}

footer a {
  list-style: none;
  color: white;
  padding: 1rem;
  cursor: pointer;
}

.footer-stick-to-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
